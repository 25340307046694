import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';


const NavBar = () => {


    return(
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
            <div className="container">
                <a className="navbar-brand" href="/">Christopher Henao</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon icon={faBars} style={{color:"#fff"}}/>
                    {/* <span className="navbar-toggler-icon" style={ {color:"#fff"} }></span> */}
                </button>
                
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link smooth={true} to='header' offset={-75} className="nav-link" aria-current="page" href="#" >Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to='about-me' offset={-75} className="nav-link" href="#">About Me</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" href="#">Services</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link smooth={true} to='experience' offset={-75} className="nav-link" href="#">Experience</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" href="#">Portfolio</Link>
                        </li> */}
                        {/* <li className="nav-item">
                            <Link className="nav-link" href="#">Link</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" href="#">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div> 
        </nav>
        
    )
}

export default NavBar;